import React, { useState } from 'react';
import './App.css';
import '@fontsource/inter';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import { CssVarsProvider } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import { NumericFormat, NumericFormatProps, numericFormatter } from 'react-number-format';
import { Box, Card, Checkbox, IconButton } from '@mui/joy';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Download from '@mui/icons-material/DownloadOutlined';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';


interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const formatterProps = {
    thousandSeparator: ".",
    decimalSeparator: ',',
    valueIsNumericString: true,
    decimalScale: 0,
    fixedDecimalScale: true,
    prefix: ""
};

const fmtEuro = (v: any) => numericFormatter(v.toString(), formatterProps);

const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatAdapter(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                {...formatterProps}
            />
        );
    },
);

const EuroInput = (props: any) => {
    const { hideSign, ...other } = props;

    return <Input
        {...other}
        startDecorator={hideSign ? '' : '€'}
        slotProps={{
            input: {
                component: NumericFormatAdapter,
            },
        }}
    />
}

const GRADIENT_BG = 'linear-gradient(180deg, #FFF 0%, #0dc06740 100%)';

function App() {
    const [winst, setWinst] = React.useState<number | null>(null);
    const [unfold, setUnfold] = useState(false);

    let preMkb = null;
    let vrijstelling = null;
    let belastbaar = null;
    let ib = null;
    let finalResult = null;


    if (winst) {
        preMkb = winst - 6310 - 2123;
        vrijstelling = (preMkb / 100 * 14);
        belastbaar = preMkb - vrijstelling;
        ib = belastbaar / 100 * 37.07;
        finalResult = ib - 2888 - 4095;
    }

    return (
        <CssVarsProvider>
            <div style={{ display: 'flex', flexDirection: 'column', background: '#f0f4f0', height: '100vh' }}>
                <Sheet
                    sx={{
                        width: 500,
                        mx: 'auto',
                        mt: 8,
                        py: 3,
                        px: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                    }}
                    variant="outlined"
                >
                    <Sheet sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography level="h3" component="h1" className='branded-title'>
                            Inkomenstenbelasting
                        </Typography>

                        <Typography level="body-sm">Je toegang verloopt op 12-01-2024</Typography>
                    </Sheet>

                    <FormControl required>
                        <FormLabel>Winst uit onderneming</FormLabel>
                        <EuroInput
                            name="winst"
                            placeholder="1.234"
                            onChange={(e: any) => setWinst(parseInt(e.target.value))}
                        />
                        <FormHelperText>€30.338 is de grens.</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Zelfstandigenaftrek</FormLabel>
                        <EuroInput value={6310}
                            endDecorator={<Checkbox size='sm' label="Krijg ik niet" />}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Startersaftrek</FormLabel>
                        <EuroInput value={2123}
                            endDecorator={<Checkbox size='sm' label="Krijg ik niet" />}
                        />
                        <FormHelperText>Kan tot drie keer worden toegpast in de eerste 5 jaar.</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <Button sx={{ mt: 1 }} variant='soft' color='neutral'>Velden leegmaken</Button>
                    </FormControl>

                    <Typography
                        endDecorator={<Link href="/">Check de rest!</Link>}
                        fontSize="sm"
                        textColor="text.tertiary"
                        sx={{ alignSelf: 'center' }}
                    >
                        Meer tools voor ondernemers nodig?
                    </Typography>
                </Sheet>

                <Card
                    sx={{
                        width: 500,
                        mx: 'auto',
                        my: 2,
                        py: 3,
                        px: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'sm',
                        boxShadow: 'md',
                    }}
                >
                    <div>
                        <Typography level="title-lg">Resultaten</Typography>
                        <Typography level="body-sm">Had je met economie maar op moeten letten, sulletje</Typography>
                        <IconButton
                            variant="plain"
                            color="neutral"
                            size="sm"
                            sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
                        >
                            <Download />
                        </IconButton>
                    </div>

                    <div>
                        {!unfold ? null : <Card sx={{
                            my: 0,
                            px: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            borderRadius: 'sm',
                        }}><FormControl>
                                <FormLabel>
                                    <Typography
                                        endDecorator={<Typography fontSize="xs">(<Link href="/">huh?</Link>)</Typography>}
                                        fontSize="sm"
                                    >Winst vóór MKB vrijstelling</Typography>
                                </FormLabel>

                                <Typography
                                    fontSize="xl"
                                    lineHeight={1}
                                    startDecorator={<Typography fontSize="lg" textColor="text.tertiary">€</Typography>}
                                    sx={{ alignItems: 'flex-start' }}
                                >
                                    {preMkb ? fmtEuro(preMkb) : '--'}
                                </Typography>
                            </FormControl>

                            <FormControl>
                                <FormLabel>
                                    <Typography
                                        endDecorator={<Typography fontSize="xs">(<Link href="/">huh?</Link>)</Typography>}
                                        fontSize="sm"
                                    >MKB vrijstelling</Typography>
                                </FormLabel>

                                <Typography
                                    fontSize="xl"
                                    lineHeight={1}
                                    startDecorator={<Typography fontSize="lg" textColor="text.tertiary">€</Typography>}
                                    sx={{ alignItems: 'flex-start' }}
                                >
                                    {vrijstelling ? fmtEuro(vrijstelling) : '--'}
                                </Typography>
                            </FormControl>

                            <FormControl>
                                <FormLabel>
                                    <Typography
                                        endDecorator={<Typography fontSize="xs">(<Link href="/">huh?</Link>)</Typography>}
                                        fontSize="sm"
                                    >Belastbaar inkomen</Typography>
                                </FormLabel>

                                <Typography
                                    fontSize="xl"
                                    lineHeight={1}
                                    startDecorator={<Typography fontSize="lg" textColor="text.tertiary">€</Typography>}
                                    sx={{ alignItems: 'flex-start' }}
                                >
                                    {belastbaar ? fmtEuro(belastbaar) : '--'}
                                </Typography>
                            </FormControl>

                            <FormControl>
                                <FormLabel>
                                    <Typography
                                        endDecorator={<Typography fontSize="xs">(<Link href="/">huh?</Link>)</Typography>}
                                        fontSize="sm"
                                    >Inkomstenbelasting</Typography>
                                </FormLabel>

                                <Typography
                                    fontSize="xl"
                                    lineHeight={1}
                                    startDecorator={<Typography fontSize="lg" textColor="text.tertiary">€</Typography>}
                                    sx={{ alignItems: 'flex-start' }}
                                >
                                    {ib ? fmtEuro(ib) : '--'}
                                </Typography>

                                <FormHelperText>37,07% over het belastbare inkomen</FormHelperText>
                            </FormControl>
                        </Card>}

                        <FormControl>
                            {
                                unfold ?
                                    <Button variant='outlined' color='neutral' onClick={() => setUnfold(false)} sx={{ mt: 1 }} endDecorator={<KeyboardArrowRight />} >Verberg berekening</Button> :
                                    <Button variant='outlined' color='neutral' onClick={() => setUnfold(true)} sx={{ mt: 1 }} endDecorator={<KeyboardArrowRight />} >Toon berekening</Button>
                            }
                        </FormControl>
                    </div>

                    <Box sx={{ display: 'flex' }}>
                        <FormControl sx={{ flex: 1 }}>
                            <FormLabel>
                                <Typography
                                    endDecorator={<Typography fontSize="xs">(<Link href="/">huh?</Link>)</Typography>}
                                    fontSize="sm"
                                >Totaal te betalen</Typography>
                            </FormLabel>

                            <Typography
                                fontSize="xl4"
                                lineHeight={1}
                                startDecorator={<Typography fontSize="lg" textColor="text.tertiary">€</Typography>}
                                sx={{ alignItems: 'flex-start' }}
                            >
                                {finalResult ? fmtEuro(finalResult) : '--'}
                            </Typography>
                        </FormControl>
                    </Box>
                </Card>
            </div>
        </CssVarsProvider>
    );
}

export default App;
